<!-- =========================================================================================
  File Name: LessonView.vue
  Description: Lesson View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/lesson/pixinvent
========================================================================================== -->

<template>
  <div id="page-lesson-view">

    <vs-alert :active.sync="lesson_not_found" color="danger" title="Lesson Not Found">
      <span>Lesson record with id: {{ $route.params.lessonId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{name:'page-lesson-list'}" class="text-inherit underline">All Lessons</router-link>
      </span>
    </vs-alert>

    <div v-if="lesson_data" id="lesson-data">

      <div class="vx-row">
        <div class="vx-col lg:w-1/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Lesson Actions">
            <!-- Avatar -->
            <div class="vx-row">

              <div class="vx-col w-full flex">
                <vs-button class="w-full" color="success" icon="icon-book" icon-pack="feather"
                           style="height:2rem;" @click="activePrompt = true">
                  Assign Module
                </vs-button>
              </div>

              <!-- /Information - Col 2 -->
              <div class="vx-col w-full flex pt-3">
                <vs-button :to="`/a/lessons/${$route.params.lessonId}/edit`" class="w-full" color="warning"
                           icon="icon-edit" icon-pack="feather" style="height:2rem;">
                  Edit
                </vs-button>
              </div>

              <!-- /Information - Col 2 -->
              <div class="vx-col w-full flex pt-3">
                <vs-button class="w-full" color="danger" icon="icon-archive" icon-pack="feather" style="height:2rem;">
                  Delete
                </vs-button>
              </div>

            </div>
          </vx-card>
        </div>

        <div class="vx-col lg:w-2/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Lesson Information">
            <table>
              <tr>
                <td class="font-semibold">Lesson Name</td>
                <td>{{ lesson_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Lesson Duration</td>
                <td>{{ (lesson_data.duration || 0) | duration }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Lesson Author</td>
                <td>
                  <router-link :to="`/a/users/${lesson_data.author_id}`">
                    {{ author_name }}
                  </router-link>
                </td>
              </tr>
            </table>

          </vx-card>
        </div>

        <div class="vx-col lg:w-2/5 w-full">
          <vx-card class="mb-base" style="height:95%" title="Lesson Attachments">
            <table>
              <tr>
                <td class="font-semibold">Attached Video</td>
                <td>
                  <router-link :to="`/a/videos/${lesson_data.video_id}`">
                    {{ video_name }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Attached Resources</td>
                <td>{{ lesson_data.attachment_ids.length }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Includes Instructions?</td>
                <td>{{ lesson_data.instructions ? 'Yes' : 'No' }}</td>
              </tr>
            </table>
          </vx-card>
        </div>

      </div>

      <vx-card class="mb-base" title="Extended Information">

        <!-- Avatar -->
        <div class="vx-row">

          <vs-col class="flex-1 m-5 mt-0">

            <vs-col vs-type="flex" w="12">
              <vs-textarea v-model="lesson_data.description" disabled height="125px" label="Lesson Description"/>
            </vs-col>

            <vs-col v-if="lesson_data.instructions" vs-type="flex" w="12">
              <vs-textarea v-model="lesson_data.instructions" disabled height="125px" label="Lesson Text Instructions"/>
            </vs-col>

          </vs-col>


        </div>

      </vx-card>

    </div>

    <vs-prompt
      :active.sync="activePrompt"
      accept-text="Submit Lesson"
      title="Add this Lesson to a Module"
      @accept="submitModuleAdd"
      @cancel="module_id=''">
      <div>
        <span>Enter the Module ID</span>
        <vs-input v-model="module_id" class="mt-3 w-full" placeholder="Module ID" vs-placeholder="Module ID"/>
      </div>
    </vs-prompt>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      lesson_data: null,
      lesson_not_found: false,
      attributes: {},

      author_name: '',
      video_name: '',

      activePrompt: false,
      module_id: '',
    };
  },
  methods: {
    submitModuleAdd() {

      this.$vs.loading();

      this.$http.put(`modules/${this.module_id}/lessons/${this.lesson_data.id}`)
        .then(response => {

          if (response.data.data) {
            this.$vs.loading.close();

            return this.$vs.dialog({
              color: 'success',
              title: `Added Lesson to Module`,
              text: 'Successfully added this lesson to the specified module',
            });

          }

          throw new Error({
            response: response
          })

        })
        .catch(exception => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while adding the lesson';

          if (exception.response) {
            if (exception.response.data.error) {
              error = exception.response.data.error.description;
            }
          }

          return this.$vs.notify({
            title: 'Failed to add lesson',
            text: error,
            color: 'danger',
          });

        });

    },
    setPhotoUrl() {
      this.lesson_data.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${
        this.lesson_data.thumbnail_id || 'default'
      }.jpeg`;
    },
    loadMeta() {
      this.$http.get(`users/${this.lesson_data.author_id}`)
        .then(response => {

          if (response.data.data) {
            const payload = response.data.data;
            this.author_name = `${payload.first_name} ${payload.last_name}`;
          }

        })
        .catch(exception => {

          let error = 'An unknown error occurred while loading the metadata of this lesson';
          if (exception.response) {
            error = exception.response.data.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load lesson author',
            text: error,
            color: 'danger',
          });

        });

      this.$http.get(`videos/${this.lesson_data.video_id}`)
        .then(response => {

          if (response.data.data) {
            const payload = response.data.data;
            this.video_name = payload.name;
          }

        })
        .catch(exception => {

          let error = 'An unknown error occurred while loading the metadata of this lesson';
          if (exception.response) {
            error = exception.response.data.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load lesson video',
            text: error,
            color: 'danger',
          });

        });
    },
    showError(exception) {

      let error = 'An unknown error occurred while modifying this lesson';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to update lesson',
        text: error,
        color: 'danger',
      });
    },
  },
  created() {

    const lessonId = this.$route.params.lessonId;

    this.$http.get(`lessons/${lessonId}`)
      .then(response => {

        if (response.data.data) {
          this.lesson_data = response.data.data;

          this.author_name = this.lesson_data.author_id;
          this.video_name = this.lesson_data.video_id;

          this.setPhotoUrl();
          this.loadMeta();
        }

      })
      .catch(error => {

        if (error.response && error.response.status === 404) {
          this.lesson_not_found = true;
          return;
        }
        console.error(error);
      });
  },
};

</script>

<style lang="scss">
#avatar-col {
  width: 15rem;
}

#page-lesson-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
